<template>
  <section class="plaindetails-area">
    <div class="container">
      <div class="plaindetails-box" v-if="type === 'depature'">
        <h5 class="text-center" v-if="type === 'depature'">{{ $t("flight-only.round-trip-departure") }}</h5>
        <figure class="logo"><img :src="markAirline" alt="departure-logo" title="" /></figure>
        <div class="plain-date">
          <div class="d-flex justify-content-between align-items-center">
            <div class="box">
              <h3>{{departHour}}</h3>
              <!-- <h4>{{ fromAirportName }}</h4> -->
              <h4>{{ fromDestName }}</h4>
            </div>
            <figure class="flight-box">
              <img :src="`${imageDomain}/assets/img/app/flight-img1.png`" alt="flight-img1" title="" />
            </figure>
            <div class="box">
              <h3>{{ arriveHour }}</h3>
              <!-- <h4>{{ arriveAirportName }}</h4> -->
              <h4>{{ toDestName }}</h4>
            </div>
          </div>
        </div>
        <p><span>{{ fromAirportName }}<br/>{{ airlineNumber }}</span></p>
        <ul class="departure-list">
          <li>{{ $t("flight-only.departure-message", {time: departHour, date: departDate, day: departWeek, airport: fromAirportName, terminal: stringTerminal(fromTerminal)}) }}</li>
          <li>{{ $t("flight-only.landing-message", {time: arriveHour, date: arriveDate, day: arriveWeek, destination: toDestName, airport: arriveAirportName, terminal: stringTerminal(arriveTerminal)}) }}</li>
        </ul>
        <div class="btn-box">
          <button class="btn-primary" v-if="data && data.Authorization_msg.includes('לא אושרו')">{{ $t("flight-only.flight-no") }}: N/A</button>
          <button class="btn-primary" v-else>{{ $t("flight-only.flight-no") }}: {{ airlineNumber }}</button>
        </div>
        <div class="baggagedetails-box">
          <h4>{{ $t("flight-only.baggage-details-title") }}</h4>
          <ul>
              <li>
                  <img :src="`${imageDomain}/assets/img/app/icon5.png`" alt="max-weight" class="img" />
                  {{$t("flight-only.luggage-description", {weight: weightLuggage})}}
              </li>
              <li>
                  <img :src="`${imageDomain}/assets/img/app/icon6.png`" alt="max-weight-hand" class="img" />
                  {{$t("flight-only.bag-description", {weight: weightBag})}}
              </li>
          </ul>
        </div>
        <div class="btn-box btn-box2">
          <button class="btn-primary">{{ authorMessage }}</button>
        </div>
      </div>
      <div class="plaindetails-box" v-else>
        <h5 class="text-center" v-if="type === 'return'">{{ $t("flight-only.round-trip-return") }}</h5>
        <figure class="logo"><img :src="markAirline" alt="return-logo" title="" /></figure>
        <div class="plain-date">
          <div class="d-flex justify-content-between align-items-center">
            <div class="box">
              <h3>{{ arriveHour }}</h3>
              <!-- <h4>{{ arriveAirportName }}</h4> -->
              <h4>{{ toDestName }}</h4>
            </div>
            <figure class="flight-box">
              <img class="arrive" :src="`${imageDomain}/assets/img/app/flight-img1.png`" alt="flight-img1" title="" />
            </figure>
            <div class="box">
              <h3>{{departHour}}</h3>
              <!-- <h4>{{ fromAirportName }}</h4> -->
              <h4>{{ fromDestName }}</h4>
            </div>
          </div>
        </div>
        <p><span>{{ arriveAirportName }}<br/>{{ airlineNumber }}</span></p>
        <ul class="departure-list">
          <li>{{ $t("flight-only.departure-message", {time: departHour, date: departDate, day: departWeek, airport: fromAirportName, terminal: stringTerminal(fromTerminal)}) }}</li>
          <li>{{ $t("flight-only.landing-message", { time: arriveHour, date: arriveDate, day: arriveWeek, destination: toDestName, airport: arriveAirportName, terminal: stringTerminal(arriveTerminal)}) }}</li>
        </ul>
        <div class="btn-box">
          <button class="btn-primary" v-if="data && data.Authorization_msg.includes('לא אושרו')">{{ $t("flight-only.flight-no") }}: N/A</button>
          <button class="btn-primary" v-else>{{ $t("flight-only.flight-no") }}: {{ airlineNumber }}</button>
        </div>
        <div class="baggagedetails-box">
          <h4>{{ $t("flight-only.baggage-details-title") }}</h4>
          <ul>
              <li>
                  <img :src="`${imageDomain}/assets/img/app/icon5.png`" alt="max-weight" class="img" />
                  {{$t("flight-only.luggage-description", {weight: weightLuggage})}}
              </li>
              <li v-if="weightBag">
                  <img :src="`${imageDomain}/assets/img/app/icon6.png`" alt="max-weight-hand" class="img" />
                  {{$t("flight-only.bag-description", {weight: weightBag})}}
              </li>
              <li v-else>
                  <img :src="`${imageDomain}/assets/img/img14.png`" alt="max-weight-hand" class="img" />
                  {{$t("flight-only.no-bag", {weight: weightBag})}}
              </li>
          </ul>
        </div>
        <div class="btn-box btn-box2">
          <button class="btn-primary">{{ authorMessage }}</button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [gMixin, imageUrlMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    airInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      markAirline: `${this.imageDomain}/assets/img/flying.png`,
    };
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      lang: 'GET_LANGUAGE',
      airportList: 'GET_AIRPORT',
    }),
    airlineName() {
      return this.data?.arl_Name ?? '';
    },
    fromRoute() {
      return this.data?.FL_From_Route ?? '';
    },
    fromDestName() {
      return this.data?.translations?.flightDestinationName?.[this.fromRoute]?.[this.lang] ?? this.fromRoute;
    },
    departHour() {
      return this.data?.FL_Dep_Hour ?? '';
    },
    departDate() {
      return this.data ? this.getDateFormat(this.data?.FL_Date) : '';
    },
    departWeek() {
      return this.data ? this.getWeek(this.data?.FL_Date) : '';
    },
    fromAirportName() {
      const currAirport = this.airportList.find((air) => air.code === this.data?.FL_From_Air_Port);
      const langName = `name${this.capitalizeFirstLetter(this.lang)}`;
      return currAirport?.[langName] ?? this.data?.FL_From_Air_Port;
    },

    toRoute() {
      return this.data?.FL_To_Route ?? '';
    },
    toDestName() {
      return this.data?.translations?.flightDestinationName?.[this.toRoute]?.[this.lang] ?? this.toRoute;
    },
    arriveHour() {
      return this.data?.FL_Arrv_Hour ?? '';
    },
    arriveDate() {
      return this.data ? this.getDateFormat(this.data?.landingDate) : '';
    },
    arriveWeek() {
      return this.data ? this.getWeek(this.data?.landingDate) : '';
    },
    arriveAirportName() {
      const currAirport = this.airportList.find((air) => air.code === this.data?.FL_To_Air_Port);
      const langName = `name${this.capitalizeFirstLetter(this.lang)}`;
      return currAirport?.[langName] ?? this.data?.FL_To_Air_Port;
    },

    airlineNumber() {
      return this.data ? `${this.data.FL_AIRLINE} - ${this.data.FL_Flt_Number}` : '';
    },
    authorMessage() {
      return this.data ? this.data.Authorization_msg : '';
    },

    fromTerminal() {
      return this.data?.FL_From_Terminal || 'N/A';
    },
    arriveTerminal() {
      return this.data?.FL_To_Terminal || 'N/A';
    },
    weightLuggage() {
      return this.data?.FL_Max_Weight || 20;
    },
    weightBag() {
      return +this.data?.FL_Max_Weight_Trolley || 0;
    },
  },
  watch: {
    airInfo() {
      if (!!this.airInfo && this.airInfo.desc2 !== '' && this.airInfo.desc2 !== undefined) {
        this.markAirline = this.airInfo.desc2 || `${this.imageDomain}/assets/img/flying.png`;
      }
    },
  },
  beforeMount() {
  },
  created() {
    if (!!this.airInfo && this.airInfo.desc2 !== '' && this.airInfo.desc2 !== undefined) {
      this.markAirline = this.airInfo.desc2 || `${this.imageDomain}/assets/img/flying.png`;
    }
  },
  methods: {
    getDateFormat(str) {
      return dayjs(str).format('DD.MM.YY');
    },
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    stringTerminal(pTerminal) {
      return pTerminal !== 'N/A' ? `( ${this.$t('flight-only.terminal')} ${pTerminal} )` : '';
    },
  },
};
</script>
<style scoped>
  img.return-air {
    transform: rotate(180deg);
  }
  .flight_box_baggage_body .flight_box_one_div .img_icon img {
    width: 50%;
    height: auto;
  }
  @media (max-width: 768px) {
    .flight_only_area .flight_only_body .flight_box_one .left_box .flight_icon {
      top: 0;
    }
    .flight_only_area .flight_only_body .flight_box_one .right_box .right_box_one .icon_derection {
      margin-top: -0.3rem;
    }
    .plaindetails-box h5 {
      width: 100%;
      padding: 0;
      margin: 0 0 15px;
      color: #000000;
      font-family: 'FbCoherentiSansBold';
      font-size: 25px;
    }
  }
</style>
